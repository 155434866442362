@import "src/assets/styles/variables";

.Title {
  margin: 0 0 50px !important;
  font-weight: 600 !important;
  font-style: italic;
  color: #ffffff;
  text-shadow: 2px 2px 10px #333a44;
}

.Subtitle {
  margin-top: 140px !important;
  font-size: 26px !important;
  color: #ffffff;
  text-shadow: 2px 2px 10px #333a44;
}

.VimButton {
  margin-top: 30px !important;
  padding: 10px 15px !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  background-color: $color-primary !important;
  transition: background-color 0.3s ease;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: darken($color-primary, 10%) !important;
  }
}

.homeIntro {
  margin-top: 80px;
  margin-inline: auto;
  max-width: 100%;
  width: 480px;
  height: 300px;
  background: url('../../assets/images/home-image.png') no-repeat center;
  background-size: cover;

  @media screen and (max-width: 1024px) {
    margin-top: 60px;
    width: 405px;
    height: 250px;
  }

  @media screen and (max-width: 599px) {
    width: 365px;
    height: 230px;
  }

  @media screen and (max-width: 499px) {
    width: 200px;
    height: 126px;
    margin: 150px auto 5px;
  }
}

.Container {
  text-align: left;

  @media screen and (max-width: 599px) {
    text-align: center;

    .Title {
      font-size: 46px;
      margin-bottom: 10px;
    }

    .Subtitle {
      margin-top: 120px !important;
      margin-bottom: 30px;
      font-size: 20px !important;
    }
  }
}

.action {
  margin-inline: auto;
  width: 480px;
  text-align: center;

  @media screen and (max-width: 599px) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    margin-top: 0;
    text-align: right;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  max-width: 150px;
  margin: 10px auto;

  @media screen and (max-width: 599px) {
    display: block;
    text-align: right;
    margin: 0 auto;
  }
}

.createButton {
  height: 100%;
}

.heading {
  padding: 20px;
}
