.imageWrapper {
  position: relative;
  max-width: 260px;  /* adjust as needed */
  width: 100%;
}

.removeImageButton {
  position: absolute;
  top: 15px;
  right: 15px;
}

.remove {
  background-color: #7e0606 !important;
  color: #ffffff !important;
}

.setMainButton {
  position: absolute;
  top: 15px;
  left: 15px;
}

.setMain {
  background-color: #068856 !important;
  color: #ffffff !important;
}

