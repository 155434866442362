.documentRow {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 30px;
}

.imagePreview {
  height: 80px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1200px) {
  .searchField {
    width: 358px;
  }
}
