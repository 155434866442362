.Layout {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(at 80% 30%, rgba(32, 73, 136, 0.6) 0, rgba(252, 220, 81, 0.3) 100%),
    url('../../assets/images/main-background.jpeg') fixed center;
  background-size: cover;
}

.Main {
  max-width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 100px;

  @media screen and (max-width: 599px) {
    padding-top: 75px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 1400px;
  }
}
