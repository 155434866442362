.Header {
  background-color: transparent !important;

  @media screen and (max-width: 899px) {
    box-shadow: none !important;
  }
}

.HeaderLink {
  margin-right: 20px;
}

.Logo {
  max-height: 50px;
}

.HeaderWrapper {
  display: flex;
  align-items: center;
}

.Navigation {
  display: flex;
  width: 100%;
  padding: 25px 0;
  justify-content: space-between;

  .NavSection {
    display: flex;
  }
}

.Drawer {
  min-width: 200px;
}

.desktopHeader {
  font-weight: bolder;
  color: #204988;
  background: #ffffff url('../../assets/images/header-background.jpg');
}
