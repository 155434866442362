@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
.vehicleMainImageWrapper {
  text-align: center;
}

.vehicleMainImage {
  max-width: 100%;
  max-height: 450px;
}

.status {
  background: #204988;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  font-family: 'Roboto Mono', sans-serif;
  color: #fff; /* Set font color to white */
}

.status:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 750px) {
  .heading,
  .status {
    display: block;
  }

  .status {
    font-size: 16px;
  }

  .buttons {
    text-align: left !important;
    margin-bottom: 10px;
  }
}
